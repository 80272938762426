import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ImgTreneriPixa from "../images/treneri/jiri-pixa.jpg"

const Practise = () => (

	<Layout>
		<Seo title="Latinský practise STK" description="Legendární úterní latinský practise na&nbsp;STK" />
		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Legendární úterní latinský practise na&nbsp;STK</h1>
			<p className="mb-4">
				Pro soutěžní taneční páry pořádáme pravidelný úterní latinský soutěžní practise.<br />
				Practise vede a&nbsp;skvělou hudbu pouští El&nbsp;Presidente <Link to="../treneri/jiri-pixa">Jiří&nbsp;Pixa</Link>.<br />
				Practise je otevřen všem tanečním párům i&nbsp;mimo STK&nbsp;Praha.
			</p>

			<Row>
				<Col xl="9" className="pb-5">

					<Row className="stk-seminar mb-2 mt-2">
						<Col lg="12" xl="6">
							<div className="stk-seminare__item" to="../../practise">
								<span className="stk-seminare__day">ÚT</span>
								<span className="stk-seminare__time">20:00<br /><span className="stk-seminare__dash">-</span><br />22:00</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Latinský practise STK</span>
									<span className="stk-seminare__subtitle">všechny soutěžní páry LAT</span>
								</span>
							</div>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2 pb-2">
							Místo:
						</Col>
						<Col xl="5">
							<p>
								<h3 className="stk-h3">Taneční sál - budova CASABLANCA</h3>
								Vinohradská 184, Praha-Vinohrady, 130&nbsp;00<br />
								<a className="stk-button stk-button--small mt-2" href="https://goo.gl/maps/gviNHcdBLMo6dmwg7" target="_blank" rel="noreferrer">zobrazit na mapě</a>
							</p>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Cena:
						</Col>
						<Col xl="10">
							<span className="stk-h2 stk-h2--yellow">200,-</span> / pár mimo STK&nbsp;Praha<br /><br />
							<span className="stk-h2 stk-h2--yellow">ZDARMA</span> / páry STK&nbsp;Praha<br />
						</Col>
					</Row>

				</Col>

				<Col xl="3" className="pb-5">
					<h2 className="stk-h2 mb-3">Vedoucí practisu:</h2>
					<Link className="stk-submenutreneri__link" to="/treneri/jiri-pixa">
						<img className="stk-submenutreneri__image" alt="Jiří Pixa" src={ImgTreneriPixa} />
						Jiří Pixa
					</Link>
				</Col>

			</Row>

		</div>
	</Layout>

)

export default Practise